/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Model(props) {
  const { nodes, materials } = useGLTF("/typing-duck-merged_40x26x40.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[Math.PI / 2, 0, Math.PI]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["typing-duck-merged_40x26x40"].geometry}
          material={materials.RGB_FFFFCC}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["typing-duck-merged_40x26x40_1"].geometry}
          material={materials.RGB_996600}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["typing-duck-merged_40x26x40_2"].geometry}
          material={materials.RGB_45CAFF}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["typing-duck-merged_40x26x40_3"].geometry}
          material={materials.RGB_FF9966}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["typing-duck-merged_40x26x40_4"].geometry}
          material={materials.RGB_FFF4E9}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["typing-duck-merged_40x26x40_5"].geometry}
          material={materials.RGB_CCCC99}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["typing-duck-merged_40x26x40_6"].geometry}
          material={materials.RGB_99CC66}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["typing-duck-merged_40x26x40_7"].geometry}
          material={materials.RGB_00CC33}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["typing-duck-merged_40x26x40_8"].geometry}
          material={materials.RGB_999900}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["typing-duck-merged_40x26x40_9"].geometry}
          material={materials.RGB_FF99FF}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["typing-duck-merged_40x26x40_10"].geometry}
          material={materials.RGB_EEEEEE}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["typing-duck-merged_40x26x40_11"].geometry}
          material={materials.RGB_FFCCFF}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["typing-duck-merged_40x26x40_12"].geometry}
          material={materials.RGB_999966}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["typing-duck-merged_40x26x40_13"].geometry}
          material={materials.RGB_777777}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["typing-duck-merged_40x26x40_14"].geometry}
          material={materials.RGB_FFFFFF}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["typing-duck-merged_40x26x40_15"].geometry}
          material={materials.RGB_FFCC00}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["typing-duck-merged_40x26x40_16"].geometry}
          material={materials.RGB_FF6600}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["typing-duck-merged_40x26x40_17"].geometry}
          material={materials.RGB_555555}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["typing-duck-merged_40x26x40_18"].geometry}
          material={materials.RGB_222222}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/typing-duck-merged_40x26x40.glb");
