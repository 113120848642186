
import React from 'react'
import ReactDOM from "react-dom"
import './style.css'
import App from "./App"
import Overlay from "./Overlay"

ReactDOM.render(<>
  <App />
  <Overlay />
</>, document.getElementById("root"))
