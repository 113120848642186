import { memo } from 'react'
import { AccumulativeShadows, RandomizedLight, Environment as EnvironmentImpl } from '@react-three/drei'

export const Environment = memo(({ direction = [15, 15, 15] }) => (
  <>
    <directionalLight position={direction} intensity={0.2} castShadow />
    <AccumulativeShadows frames={200} alphaTest={0.85} opacity={0.75} scale={30} position={[0, -1.5, 0]}>
      <RandomizedLight amount={10} radius={1.5} ambient={0.7} intensity={1.2} position={direction} bias={0.001} />
    </AccumulativeShadows>
    <EnvironmentImpl preset="park" />
  </>
))
