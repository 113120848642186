
import React, {Suspense, useRef} from 'react'
import {Canvas} from '@react-three/fiber'
import {Grid, OrbitControls, Stage} from '@react-three/drei'
import {EffectComposer, Bloom} from '@react-three/postprocessing'
import {Model} from './Model'
import {Environment} from './Environment'

export default function Viewer() {
  const ref = useRef()
  return (
    <Canvas shadows camera={{position: [-5, 10, 20], fov: 50}}>
      <color attach="background" args={['skyblue']} />
      <Suspense fallback={null}>
        <Stage intensity={0.001} environment="park" adjustCamera={false}>
          <Model />
          <Environment />
        </Stage>
      </Suspense>
      <Grid
        renderOrder={-1}
        position={[0, -2.6, 0]}
        infiniteGrid
        cellSize={1}
        cellThickness={0.6}
        sectionSize={3}
        sectionThickness={1.5}
        sectionColor={[0.52, 0.80, 0.92]}
        fadeDistance={50} />
      <OrbitControls
        ref={ref}
        autoRotateSpeed={0.5}
        enableZoom={false}
        makeDefault
        minPolarAngle={(Math.PI / 2) - 0.4}
        maxPolarAngle={(Math.PI / 2) - 0.4}
        autoRotate />
    </Canvas>
  )
}
